.container {
  display: flex;
  justify-content: space-between;
  width: 85%;
  padding-left: 4%;
}

.titleAggregator {
  margin-top: 1%;
  margin-left: 1%;
}
.searchBtn {
  display: flex;
  justify-content: flex-end;
  padding-right: 4%;
  padding-top: 0.5%;
  margin-top: 8.6%;
}

.btn {
  width: 140px;
}
.accordionContainer {
  margin-top: 20px;
}

.accordionContainer .ant-collapse-header {
  font-size: 16px;
  font-weight: bold;
}
.customButton {
  left: 1190px;
  margin: 10px;
}
.deleteGameColumn {
  display: flex;
  align-items: center;
}
.tooltip {
  margin-left: 8px;
  color: #1890ff;
  cursor: pointer;
}

.tooltip {
  margin-left: 8px;
  color: #1890ff;
  cursor: pointer;
}
.selectButton {
  left: 80%;
}
.customTooltip {
  color: #e72a2a;
  font-size: 14px;
  line-height: 1.2;
  width: 400px;
  text-align: justify;
  padding: 10px;
  background-color: #fff;
}
