.dashboard {
  width: 100%;
}

.btn {
  margin: 0.1%;
  padding-left: 1.5%;
  padding-right: 1.5%;
  // width: 10%;
  border-color: #d9d9d9 !important;
  color: black !important;
}

.btnActive {
  margin: 0.1%;
  padding-left: 1.5%;
  padding-right: 1.5%;
  // width: 10%;
  border-color: #1976ff !important;
  color: #1976ff !important;
}

.btn:hover {
  border-color: #1976ff !important;
  color: #1976ff !important;
}

.dataPicker {
  width: 26%;
  margin: 0.1%;
}
.dataPickerMob {
  margin: auto;
  width: 100%;
}

.currencySelect {
  width: 9%;
  margin: 0.2%;
}

.currencySelectMob {
  width: 25%;
  margin: 0.3%;
  margin-top: 1%;
}

// .filtersContainer {
//   display: inline;
// }

.generalInfoSection {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  justify-content: center;
}

.infoTitle {
  margin-top: 6%;
  margin-left: 1%;
}

.customCardTitle {
  color: #000;
  transition: color 0.2s ease-in-out;
}

.financicalInfoSection {
  margin-bottom: 5%;
  margin-top: 1%;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  justify-content: center;
}

.categoryBtn {
  background-color: #1976ff;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.asd {
  width: 10px;
}

.filtersDropDown {
  width: 80%;
  right: 1%;
  margin: 1%;
}

.generalCards {
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.generalCards {
  text-align: center;
  margin-top: 10px;
  border: 1px solid #d9d9d9 !important;
  // cursor: pointer;
  transition: transform 0.2s ease-in-out;
  height: 90%;
}

.disabledCard {
  background-color: #f7f7f7;
  opacity: 0.6;
  pointer-events: none;
}

.filtersContainerWrapper {
  position: fixed;
  // top: 7%;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: white;
  padding: 1%;
  padding-left: 11%;
  padding-right: 2%;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filtersContainerWrapperNavOpen {
  position: fixed;
  top: 7%;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: white;
  padding: 2%;
  padding-left: 15%;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1320px) {
  .dataPicker {
    width: 47vh;
    margin: 0.2%;
  }

  .filtersContainerWrapper {
    padding-left: 10%;
  }

  .filtersContainerWrapperNavOpen {
    padding-left: 5%;
  }
}

@media screen and (max-width: 1288px) {
  .filtersContainerWrapper {
    position: fixed;
    top: 7%;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: white;
    padding: 0.5%;
    padding-left: 10%;
  }

  .filtersContainerWrapperNavOpen {
    position: fixed;
    top: 7%;
    margin-left: 200px;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: white;
    padding: 2%;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

.pickerFocused {
  border-color: #1976ff;
  border-inline-end-width: 1px;
}

.boldRow {
  font-weight: bold;
}

@media screen and (min-width: 590px) and (max-width: 995px) {
  .dataPickerMob {
    margin: auto;
    width: 60%;
  }

  .filtersDropDown {
    width: 47%;
    right: 1%;
    margin: 1%;
  }

  .currencySelectMob {
    width: 10%;
    margin: 0.3%;
    margin-top: 1%;
  }
}

.tooltipeIcon {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 3%;
  margin-right: 6%;
}
