.checkbox {
  display: flex;
  flex-direction: column;

  label {
    font-weight: 500;
  }
}

.checkbox_name {
  display: inline-block;
  min-width: 110px;
  margin-right: 5px;
  font-weight: 600;
}
