.cardDropdown {
  width: 120px;
  float: right;
}

.cardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardRightSide span,
.cardLeftSide span {
  display: block;
}
