.root {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  padding: 0;
}

.headerActions {
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: relative;
  justify-content: space-between;
}

.trigger {
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center; /* Optional: Center items vertically */
}

.projectSelect {
  width: 15vh;
  margin-right: 12%;
}

@media screen and (max-width: 800px) {
  .projectSelect {
    // width: 13vh;
    margin-right: 5%;
  }
}

.mobileRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  width: 100% !important;

  .ant-select {
    margin-top: 8px;
  }
}

.tooltipeHeadrIcon {
  // position: absolute;
  // top: 10;
  margin-top: -10px;
}
