.projectBtn {
  width: 180px;
  height: 90px;
  padding: auto;
  margin: 5px;
  cursor: pointer;
  border: none;
  color: white;
  border-radius: 10px;
  background-color: #00415d;

  p {
    color: cyan;
  }
}

.projectBtn:hover {
  width: 180px;
  height: 90px;
  padding: auto;
  margin: 5px;
  cursor: pointer;
  border: none;
  color: white;
  background-color: #2bbbad;

  p {
    color: cyan;
  }
}

.projectContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20%;
  flex-wrap: wrap;
  margin-top: -10%;
}
