.root {
  height: 100%;
}
.userInfoTitle {
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
}
.userInfoTextBody {
  margin: 0.6rem;
  font-size: medium;
}
