.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 4%;
}

.form {
  overflow-x: auto;
  white-space: nowrap;
  width: 80%;
}

.searchBtn {
  display: flex;
  justify-content: flex-end;
  padding-right: 4%;
  padding-top: 0.5%;
}

.btn {
  width: 150px;
}
.accordionContainer {
  margin-top: 20px;
}

.accordionContainer .ant-collapse-header {
  font-size: 16px;
  font-weight: bold;
}

.customButton {
  left: 1190px;
  margin: 10px;
}
.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-item {
  text-align: center;
}

.modalContent {
  display: flex;
  padding: 10px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fieldGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.label {
  width: 20%;
  font-weight: bold;
  padding-bottom: 25px;
}

.inputField {
  flex: 1;
  margin-left: 9px;
}

.filterFilledIcon {
  font-size: 20px;
  cursor: pointer;
  margin: 10px;
  margin-top: -5px;
  margin-left: 1000px;
}
.filterOutlinedIcon {
  font-size: 20px;
  cursor: pointer;
  margin: 10px;
  margin-top: -5px;
  margin-left: 1000px;
}

.recommendedSize {
  font-size: 12px;
  color: #888;
  margin-top: 3px;
}

.dropdownContent {
  background-color: #f5f5f5;
  height: 400px;
  width: 500px;
  right: -1400%;
  padding: 10px;
  padding-right: 10px;
  border-radius: 7px;
  margin-top: 5px;
  position: absolute;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.262);
}
.filterSerchBtn {
  margin: 10px;
  left: 400px;
  margin-bottom: 30px;
  margin-top: -40px;
}
.resetBtn {
  margin: 10px;
  left: 240px;
  margin-bottom: 30px;
  margin-top: -40px;
}
.priority-message {
  margin: 3px;
  font-size: 11px;
  line-height: 1.5;
}
